import { render, staticRenderFns } from "./QPlacedPolicyDataTable.vue?vue&type=template&id=60a66437&scoped=true&"
import script from "./QPlacedPolicyDataTable.vue?vue&type=script&lang=js&"
export * from "./QPlacedPolicyDataTable.vue?vue&type=script&lang=js&"
import style0 from "./QPlacedPolicyDataTable.vue?vue&type=style&index=0&id=60a66437&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a66437",
  null
  
)

export default component.exports