<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agency-building-submenu></q-agency-building-submenu>
        <!-- Charts-->
        <div class="mx-5 q-sticky-buffer">
            <v-progress-linear color="primary" indeterminate v-if="agent == null"></v-progress-linear>
            <div v-if="agent">
                <p>DBA name placeholder: {{ agent.DoingBusinessAsName }}</p>
                <p>DBA NPN number placeholder: {{ agent.DoingBusinessAsNPN }}</p>
                <p>Agent NPN number placeholder: {{ agent.NPN }}</p>
                <p>Agent Status placeholder: {{ agent.Status }}</p>
                <h5>
                    <v-badge color="blue" :content="agent.ContractLevel" icon="fas fa-crown">
                        {{ agent.AgentName }}
                    </v-badge>
                    <div class="agent-title mt-2 mb-3">
                        {{ agent.Division }} -
                        {{ agent.LeadershipLevel }}
                        <span v-if="agent.ProducerLevel"> - {{agent.ProducerLevel}}</span>
                    </div>
                </h5>
                <QTabs dark>
                    <v-tab>Profile</v-tab>
                    <v-tab>Licensing</v-tab>
                    <v-tab>Documents</v-tab>
                    <v-tab v-if="hasRole('SuperAdmin')">Raw Data</v-tab>
                    <v-tab-item class="profile-info">
                        <v-row class="text-left pa-3 cms" wrap>
                            <v-col cols="12" sm="8">
                                <v-list style="max-width:500px;">
                                    <v-list-item>
                                        <v-list-item-icon class="text-center" style="width:30px;">
                                            <v-icon color="q_green_1" style="margin:0 auto;">fas fa-envelope</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <a :href="'mailto:' + agent.AgentEmail">{{ agent.AgentEmail }}</a>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-icon class="text-center" style="width:30px;">
                                            <v-icon color="q_green_1" style="margin:0 auto;">fas fa-user-secret</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <div class="overline">Agent Code:</div>
                                                <div>{{ agent.AgentCode }} </div>
                                                <div class="overline" style="padding-top:1em;">Opt ID:</div>
                                                <div>{{ agent.OptID }}</div>
                                                <div v-if="agent.ContractStartDt">
                                                    <div class="overline" style="padding-top:1em;"><b>Start Date:</b></div>
                                                    <div>{{ formatDateCalendarWithYear(agent.ContractStartDt) }}</div>
                                                </div>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-icon class="text-center" style="width:30px;">
                                            <v-icon style="margin:0 auto;" color="q_green_1">fas fa-crown</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <div class="overline text-wrap" style="display:inline-block:">Leadership Level:</div>
                                                <div style="display:inline-block:"> {{ agent.LeadershipLevel }}<span v-if="agent.ProducerLevel">, {{agent.ProducerLevel}}</span></div>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-icon class="text-center" style="width:30px;">
                                            <v-icon color="q_green_1" style="margin:0 auto;">fas fa-phone</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <a :href="'tel:' + agent.AgentPhone">{{ agent.AgentPhone }}</a>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-icon class="text-center" style="width:30px;">
                                            <v-icon color="q_green_1" style="margin:0 auto;">fas fa-address-card</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ agent.StreetAddress }}<br>
                                                {{ agent.City }}, {{ agent.State }} {{ agent.Zip }}<br>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                            <v-col cols="12" sm="4" class="px-6">
                                <q-agent-upline :agent="agent"></q-agent-upline>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item class="licensing-info">
                        <div class="pa-4">
                            <h2>Licensing</h2>
                            <q-agent-licensing-agent-sync :agent="agent"></q-agent-licensing-agent-sync>
                        </div>
                    </v-tab-item>
                    <v-tab-item class="licensing-info">
                        <div class="pa-4">
                            <q-agent-documents :agent="agent"></q-agent-documents>
                        </div>
                    </v-tab-item>
                </QTabs>
            </div>
        </div>
    </v-container>
</template>
<script>
import QAgencyBuildingSubmenu from '@/components/navigation/Submenus/QAgencyBuildingSubmenu.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Agent from '@/store/Models/Agent'
import QAgentUpline from '@/components/lists/QAgentUpline.vue';
import QAgentDocuments from '@/components/agent_admin/QAgentDocuments.vue'
import QTabs from '../../components/navigation/QTabs.vue'
import QAgentLicensingAgentSync from "@/components/utils/QAgentLicensingAgentSync";

export default {
    name: "AgentDetail",
    data() {
        return {
            agentcode: null,
            agentdownline: {},
            current_agent: null,
            stickyTop: 0,
            date_period: 'monthly',
            editUpline: false,
            note_count: 0,
        };
    },
    mounted: function() {
        this.reset();
    },
    computed: {
        'agent': function() {
            return this.$store.state.entities.agent.currentAgent;
        },
        loading: function() {
            return this.$store.state.entities.agent.loading;
        },
    },
    methods: {
        showAgent(agent) {
            this.current_agent = agent
        },
        reset: function() {
            this.agentcode = this.$route.params.agentcode;
            this.note_count = 0;
            QuilityAPI.getAgent(this.agentcode, ['LeadershipLevel', 'LastLoginDate','FastTrack']);
        },
        getDownline: function() {
            return this.recursiveDownline(this.agent.Downline)
        },
        getAgentDownline: function(AgentCode) {
            var g = this
            QuilityAPI.getAgentDownline(AgentCode, [], function(json) {
                g.agentdownline[AgentCode] = json
            });
        },
        recursiveDownline: function(children) {
            var str = ""
            for (var i = 0; i < children.length; i++) {
                str = str + "<a href='/admin/agents/" + children[i].AgentCode + "'>" + children[i].AgentName + "</a><br>";
                if (children[i].Downline == null) continue;
                if (children[i].Downline.length > 0) {
                    str = str + this.recursiveDownline(children[i].Downline);
                }
            }
            return str;
        },
        loadAgent(agentcode) {
            this.$router.push('/admin/agents/' + agentcode)
        },
        updateNoteCount(notes) {
            this.note_count = notes.meta.note_count;
        }
    },
    watch: {
        '$route.params.agentcode': function(newV, oldV) {
            if (typeof newV != 'undefined' && this.agentcode != newV) {
                this.reset();
            }
        },
        agent() {
            this.setPageTitle((this.agent && this.agent.AgentName) || 'Agent Detail')
        },
    },
    components: {
        QAgentLicensingAgentSync,
        QAgencyBuildingSubmenu,
        QAgentUpline,
        QAgentDocuments,
        QTabs,
    }
};

</script>
<style scoped>
blockquote {
    margin-left: 50px;
}

</style>
