<template>
    <v-container fluid class="my-0 px-0  py-0" height="100%">
        <v-row class="d-flex justify-center">
            <v-card cols="12" v-if="policy" flat align="stretch" class="text-left">
                <v-progress-linear color="q_leads_1" indeterminate v-if="policy == null"></v-progress-linear>
                <v-card-title style="background-color:#360568; color:white;">
                    <strong>Pending Policy Details</strong>
                </v-card-title>
                <template>
                    <v-simple-table>
                        <tbody>
                            <tr>
                                <td>Carrier:</td>
                                <td><strong>{{ policy.CarrierName }}</strong>
                                </td>
                                <td>
                                    <v-btn icon right small class="ml-2" color="q_leads_3" :href="policy.CarrierLoginLink" target="_blank">
                                        <v-icon> fas fa-sign-in-alt</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            <tr>
                                <td>Product:</td>
                                <td colspan="2"><strong>{{ policy.ProductName }}</strong></td>
                            </tr>
                            <tr>
                                <td>Application Date:</td>
                                <td colspan="2"><strong>{{ policy.ReceivedDate=="NA" ? "NA" : formatDateDash(policy.ReceivedDate) }}</strong></td>
                            </tr>
                            <tr>
                                <td>Insured</td>
                                <td colspan="2"><strong>{{ policy.InsuredName }}</strong></td>
                            </tr>
                            <tr>
                                <td>Insured Phone:</td>
                                <td colspan="2"><span v-if="policy.InsuredPhone"><strong>{{ policy.InsuredPhone }}</strong></span><span v-else>NA</span></td>
                            </tr>
                            <tr>
                                <td>Insured Email: </td>
                                <td colspan="2"><span v-if="policy.InsuredEmail"><strong>{{ policy.InsuredEmail }}</strong></span><span v-else>NA</span></td>
                            </tr>
                            <tr>
                                <td>Policy Number:</td>
                                <td colspan="2"><span v-if="policy.PolicyNumber"><strong>{{ policy.PolicyNumber }}</strong></span><span v-else>NA</span></td>
                            </tr>
                            <tr>
                                <td>Policy Status:</td>
                                <td colspan="2"><span v-if="policy.PolicyStatus"><strong>{{ policy.PolicyStatus }}</strong></span><span v-else>NA</span></td>
                            </tr>
                            <tr>
                                <td>Status Reasons:</td>
                                <td colspan="2"><span v-if="policy.StatusReason"><strong>{{ policy.StatusReason }}</strong></span><span v-else>NA</span></td>
                            </tr>
                            <tr>
                                <td>Annualized Premium:</td>
                                <td colspan="2"><span v-if="policy.AnnualizedPremium"><strong>{{ formatCurrency(policy.AnnualizedPremium) }}</strong></span><span v-else>NA</span></td>
                            </tr>
                            <tr>
                                <td>Status: </td>
                                <td colspan="2"><span v-if="policy.OrigStatus"><strong>{{ policy.OrigStatus }}</strong></span><span v-else>NA</span></td>
                            </tr>
                            <tr>
                                <td>Face Amount: </td>
                                <td colspan="2"><span v-if="policy.FaceAmount"><strong>{{ formatCurrency(policy.FaceAmount) }}</strong></span><span v-else>NA</span></td>
                            </tr>
                            <tr>
                                <td>Writing Number: </td>
                                <td colspan="2"><span v-if="policy.FaceAmount"><strong>{{ policy.WritingNumber }}</strong></span><span v-else>NA</span></td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </template>
            </v-card>
        </v-row>
    </v-container>
</template>
<script>
export default {
    props: [
        'policy'
    ],

    name: "QPendingPolicyDetail",
    data() {
        return {};
    },
    mounted: function() {},
    computed: {},
    methods: {},

    watch: {},
    components: {}
}

</script>
<style scoped>
blockquote {
    margin-left: 50px;
}

</style>
