<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-product-library-agent-submenu></q-product-library-agent-submenu>
        <v-row justify="space-between" align="stretch" class="mx-5 q-sticky-buffer">
            <v-col cols="12">
                <q-agent-product-data-table :carrier_id="carrier_id"></q-agent-product-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QAgentProductDataTable from '@/components/datatables/QAgentProductDataTable.vue'
import QProductLibraryAgentSubmenu from '@/components/navigation/Submenus/QProductLibraryAgentSubmenu.vue';
export default {
    name: "Products",
    data() {
        return {

        };
    },
    mounted: function() {
    
    },
    computed: {
        "carrier_id": function() {
            return this.$route.params.carrier_id ? this.$route.params.carrier_id : false
        },
    },
    methods: {

    },
    components: {
        QAgentProductDataTable,
        QProductLibraryAgentSubmenu
    }
};

</script>
