<template>
    <div>
        <v-card flat>
            <v-container fluid class="ma-0 pa-0" id="lead_datatable">
                <v-row class="q_leads_1">
                    <v-col cols="12" sm="6" id="q-pending-policy-step-3" class="mb-0 pb-0 px-7">
                        <p class="q-display-3  pb-0 pt-0 mt-0 mb-0 white--text">
                            {{ title }}
                            <v-btn dark icon @click="refresh">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </p>
                        <p class="pt-0 mt-0 ">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filter Products
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="d-flex justify-start" v-show="showFilters">
                    <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys" :checkboxesFilters="['CurrentPaymentMethodInd']"></q-filters>
                </v-row>
                <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" v-model="selected" :headers="the_headers" :items="all_data" item-key="id" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items" :search="search">
                    <!--adds view ticket icon -->
                    <template v-slot:item.ID="{ item }">
                        <v-btn @click="viewAgentLedger(item.AgentCode)">Ledger</v-btn>
                    </template>
                    <template v-slot:item.PaymentMethodValidInd="{ item }">
                        <span v-if="item.PaymentMethodValidInd">
                            <v-icon>fas fa-check</v-icon>
                        </span>
                    </template>
                    <template v-slot:item.CurrentPaymentMethodInd="{ item }">
                        <span v-if="item.CurrentPaymentMethodInd">
                            <v-icon>fas fa-check</v-icon>
                        </span>
                    </template>
                    <template v-slot:item.TopOffAmount="{ item }">
                        <strong>{{ formatLongCurrency(item.TopOffAmount) }}</strong>
                    </template>
                    <template v-slot:item.Balance="{ item }">
                        <strong>{{ formatLongCurrency(item.Balance) }}</strong>
                    </template>
                    <template v-slot:item.AgentName="{ item }">
                        <router-link :to="{ name: 'AgentDetail', params: { agentcode: item.AgentCode} }">{{item.AgentName}}</router-link>
                    </template>
                </v-data-table>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "../QDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QAgentAccountsDataTable",
    props: [],
    mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            //use this to "preset" a filter
            filters: { 'CurrentPaymentMethodInd': 1 },
            options: {
                'sortBy': ['CreateDate'],
                'sortDesc': [true],
            },
            expanded: [],
            the_data: [],
            data_filters: null,
            data_loading: false,
            total_num : 0
        }
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return ['PaymentType', 'CurrentPaymentMethodInd'];
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers: function() {
            return [{
                    text: '',
                    value: 'ID',
                    sortable: true,
                    align: "left"
                }, {
                    text: 'Created',
                    value: 'CreateDate',
                    sortable: true,
                    align: "left"
                }, {
                    text: 'Agent',
                    value: 'AgentName',
                    sortable: false,
                    align: "left"
                }, {
                    text: 'Agent Code',
                    value: 'AgentCode',
                    sortable: false,
                    align: "left"
                }, {
                    text: 'Type',
                    value: 'PaymentType',
                    sortable: true,
                    align: "left"
                },
                {
                    text: 'Last #',
                    value: 'PaymentLastFour',
                    sortable: true,
                    align: "left"
                },
                {
                    text: 'Top Off Amount',
                    value: 'TopOffAmount',
                    sortable: true,
                    align: "right"
                },
                {
                    text: 'Valid',
                    value: 'PaymentMethodValidInd',
                    sortable: true,
                    align: "Center"
                },
                {
                    text: 'Current',
                    value: 'CurrentPaymentMethodInd',
                    sortable: true,
                    align: "center"
                },
                {
                    text: 'Balance',
                    value: 'Balance',
                    sortable: false,
                    align: "right"
                },

            ]
        },
        available_filters: function() {
            return this.data_filters
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            if (this.data_filters == null) {
                opt.needAvailableFilters = true
            }
            this.data_loading = true
            QuilityAPI.getAgentAccounts(this.filters, opt).then(function(json) {
                g.data_loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                g.$set(g, 'the_data', json.data);
                g.total_num = json.meta.total
                if (typeof json.meta != undefined && g.data_filters == null && typeof json.meta.filters != undefined) {
                    g.data_filters = json.meta.filters
                }
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
            this.firstPull = false;
        }, 200),
        refresh: function() {
            this.the_data = [];
            this.refreshData();
        },
        viewAgentLedger: function(agent_code) {
            this.$router.push({ path: '/agents/' + agent_code + '/account_balance' })
        }
    },
    watch: {
        'options': function() {
            this.refreshData();
        },
    },
    components: {}
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}
</style>