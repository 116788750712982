<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-agency-building-submenu></q-agency-building-submenu>
        <v-row justify="space-between" align="stretch" class="mx-5 q-sticky-buffer" id="EverPlans">
            <v-col cols="12">
                <h2>My DFL Agent Pool</h2>
                <q-agent-calendar-link-form :calendar-agent-code="agent.AgentCode" @calendarLinkAdded="updateCalendarLinkTable"></q-agent-calendar-link-form>
                <q-agent-calendar-link-table title="DFL Calendar Links" :calendar-agent-code="agent.AgentCode" ref="linktable"></q-agent-calendar-link-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import QAgentCalendarLinkForm from '@/components/form/QAgentCalendarLinkForm'
import QAgentCalendarLinkTable from '@/components/datatables/QAgentCalendarLinkTable'
import QAgencyBuildingSubmenu from '@/components/navigation/Submenus/QAgencyBuildingSubmenu.vue';
export default {
    name: 'AgentCalendarLinks',
    props: {

    },
    computed: {
        'agent': function() {
            if (typeof newV != 'undefined' && this.agentcode != newV) {
                return this.$store.state.entities.agent.currentAgent;
            }
            return this.user.Agent;
        },
    },
    methods: {
        updateCalendarLinkTable()
        {
            this.$refs.linktable.refreshData()
        }
    },
    components: {
        QAgentCalendarLinkForm,
        QAgentCalendarLinkTable,
        QAgencyBuildingSubmenu,
    },
}
</script>
<style scoped>
blockquote {
    margin-left: 50px;
}

</style>
