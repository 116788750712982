<template>
    <div>
        <v-row :class="!$vuetify.breakpoint.sm && 'flex-wrap-reverse'">
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-container fluid class="ma-0 pa-0 q_leads_1" id="lead_datatable">
                        <QDataTableHeader color="q_leads_1" ratio="5/7">
                            <template #left>
                                <div id="q-pending-policy-step-3">
                                    <p class="q-display-3 d-flex align-center white--text">
                                        {{ title }}
                                        <v-btn dark icon @click="refreshData">
                                            <v-icon>fas fa-redo-alt</v-icon>
                                        </v-btn>
                                    </p>
                                </div>
                            </template>
                        </QDataTableHeader>
                    </v-container>
                    <v-data-table
                        :mobile-breakpoint="2"
                        :headers="allHeaders"
                        :items="all_data"
                        item-key="id"
                        class="elevation-0"
                        :footer-props="{itemsPerPageOptions:[10,25,50,100]}"
                        :loading="data_loading"
                        :search="search"
                        >
                        <template #item.Approved="{ item }">
                            <span style="word-break: break-all;">{{ item.Approved ? 'Yes' : 'No' }}</span>
                        </template>
                        <template #item.Delete="{ item }">
                            <v-btn color="red" small fab icon @click.stop="deleteLink(item)">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin.js"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDataTableHeader from '../datatables/base/QDataTableHeader.vue'
import { debounce } from 'vue-debounce'

export default {
    //override this.
    name: "QAgentCalendarLinkTable",
    mixins: [QDataTableMixin],
    props: ['calendarAgentCode','title'],
    data: function() {
        return {
            calendar_agent: null,
            data_loading: false,
            response_data: null,
            search: '',
        }
    },
    mounted: function() {
        this.refreshData()
    },
    computed: {
        filter_keys: function() {
            // these should match up with the keys for the model on the server side - availableFilters
        },
        all_data: function() {
            //instead of using a state/model on the frontend the data is all contained in this module.
            //this computed propery is required by the QDataTableMixin
            return this.response_data ?? []
        },

        allHeaders() {
            let h = [
                {
                    text: 'Agent Name',
                    value: 'Agent.AgentName',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                },
                {
                    text: 'Calendar URL',
                    value: 'CalendarURL',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                },
                // {
                //     text: 'Approved',
                //     value: 'Approved',
                //     align: 'start',
                //     sortable: true,
                //     divider: true,
                //     filterable: true
                // },
            ]

            // if(this.hasRole(['SuperAdmin','Exec','Staff']))
            // {
            //     h.push({
            //         text: 'Approved By',
            //         value: 'ApprovedBy',
            //         align: 'start',
            //         sortable: true,
            //         divider: true,
            //         filterable: true
            //     })
            // }

            h.push({
                text:'Delete',
                value:'Delete',
                align:'start',
                sortable: false,
                divider:true,
                filterable: false,
                width: '1%'
            })

            return h
        },

        //server_available_filters will be set by our first data pull where the server responds with all data for the filter drop downs.
        //QDataTableMixin requires this computed property.
        'available_filters': function() {
            return this.server_available_filters
        },
        is_loading: function() {
            return this.data_loading
        }
    },
    methods: {
        //get called by the QDataTableMixin whenever we need to refresh the data from the server.
        loadData: debounce(function(opt) {
            this.data_loading = true
            let g = this
            // pull data
            QuilityAPI.getAgentConfigsAppointmentCalendar(this.calendarAgentCode)
            .then(function (json) {
                g.response_data = json.data
                g.data_loading = false
            })
            .catch(function(err) {
                console.error('Issue with loading in calendar link data',err)
                g.showError("Error loading calendar links.")
                g.data_loading = false
            })
        }, 200),
        deleteLink(linkdata){
            this.data_loading = true
            let g = this
            let i = this.response_data.indexOf(linkdata)
            let cfg_agent = linkdata.ConfigAgent ? linkdata.ConfigAgent.AgentCode : this.user.Agent.AgentCode;
            QuilityAPI.deleteAgentConfigAppointmentCalendar(cfg_agent, {'agent_code': linkdata.Agent.AgentCode})
            .then(function (json) {
                g.response_data = json.data
                g.data_loading = false
            })
            .catch(function(err) {
                console.error('Issue with loading in calendar link data',err)
                g.showError("Error loading calendar links.")
                g.data_loading = false
            })
        },
    },
    watch: {
    },
    components: {
        QDataTableHeader,
    }
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}
</style>