<template>
    <div>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <v-form v-else-if="AgentLevel" v-model="valid" ref="form" :disabled="saving">
            <p>Note: Only Leadership levels are currently managed through HQ. This will also update the level in Opt.</p>
            <v-row wrap dense>
                <v-col cols="12" v-if="error">
                    <v-alert type="error">
                        {{ error }}
                    </v-alert>
                </v-col>
                <v-col cols="12" v-if="warning">
                    <v-alert type="warning">
                        {{ warning }}
                    </v-alert>
                </v-col>
                <v-col cols="12">
                    <v-select :disabled="saving" :rules="[form_rules.required]" label="Level Type" v-model="AgentLevel.LevelType" :items="level_types"></v-select>
                    <v-select :disabled="saving" :rules="[form_rules.required]" label="Level" :items="levels" item-text="LevelName" item-value="id" v-model="AgentLevel.LevelID"></v-select>
                </v-col>
                <v-col cols="6">
                    <v-checkbox :disabled="saving" label="Override" v-model="showOverride"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox :disabled="saving" label="Locked" v-model="AgentLevel.LockedInd"></v-checkbox>
                </v-col>
                <v-col cols="12">
                    <v-select :disabled="saving" v-if="showOverride" label="Level Override" :items="levels" item-text="LevelName" item-value="id" v-model="AgentLevel.OverrideLevelID"></v-select>
                    <q-date-picker :disabled="saving" v-if="AgentLevel.id == null" :rules="[form_rules.required, form_rules.date]" v-model="AgentLevel.StartDate" label="Start Date"></q-date-picker>
                    <div v-if="AgentLevel.id != null">
                        <q-date-picker :disabled="saving" :rules="[form_rules.required, form_rules.date]" v-model="AgentLevel.StartDate" label="Start Date"></q-date-picker>
                        <q-date-picker :disabled="saving" :rules="[form_rules.date]" v-model="AgentLevel.EndDate" label="End Date"></q-date-picker>
                    </div>
                    <v-textarea v-model="AgentLevel.Notes" label="Notes"></v-textarea>
                </v-col>
                <v-col cols="12">
                    <v-btn block color="primary" @click="saveLevel">
                        Save
                        <v-progress-circular :disabled="saving" right size="30" v-if="saving" indeterminate></v-progress-circular>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
import QDatePicker from '@/components/utils/QDatePicker.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import Level from '@/store/Models/AgentManagement/Level'
import moment from 'moment'

export default {
    props: ['value', 'agent'],
    data() {
        return {
            AgentLevel: this.value,
            LevelType: "Leadership",
            saving: false,
            warning: null,
            error: null,
            valid: false,
            showOverride: this.value.OverrideLevelID > 0 ? true : false,
            endDateVal: this.value.EndDate,
            currentDateVal: null,
            beforeDateValFrom: null,
            beforeDateValTo: null,
            afterDateValFrom: null,
            afterDateValTo: null,
            historyLevels: [
                { StartDate: null }
            ],
            history: [],
        }
    },
    mounted: function() {
        this.getAgentLevels()
        this.getLevels()

    },
    computed: {
        'levels': function() {
            const g = this
            const r = Level.query().where("LevelType", g.AgentLevel.LevelType).get()
            let currentLevelIndex = null
            let agentLevelStartDate = g.AgentLevel.StartDate

            // Check if any historyLevels exist before adding a new level
            if (g.AgentLevel.StartDate != null && g.AgentLevel.EndDate == null) {
                let currentStartDateValFormat = null
                if (g.historyLevels == null) {
                    g.$store.state.entities.level.loading = true
                    return g.getAgentLevels()
                } else {
                    g.$store.state.entities.level.loading = false
                    currentStartDateValFormat = moment(g.historyLevels[0].StartDate, "YYYY-MM-DD")
                }
                let agentLevelStartDateFormat = moment(agentLevelStartDate, "YYYY-MM-DD")
                if (agentLevelStartDateFormat.isBefore(currentStartDateValFormat)) {
                    g.beforeDateValFrom = currentStartDateValFormat
                }
                return r
            }

            // Filtering the history level's list to map only the before and after indexes from user selected level 
            const filteredHistoryLevels = g.historyLevels.filter((item, index) => {
                if (g.AgentLevel.LevelID == item.LevelID) {
                    // Get the current selected level index 
                    currentLevelIndex = index
                    // Get the previous Level EndDate value
                }
                return index !== currentLevelIndex;
            });
            
            // Validading if is before or after user selected level
            filteredHistoryLevels.forEach(function callback(item, index) {
                if (index > 0 || moment(item.StartDate, "YYYY-MM-DD").isBefore(agentLevelStartDate)){
                    g.beforeDateValFrom = item.StartDate
                    g.beforeDateValTo = item.EndDate
                } else {
                    g.afterDateValFrom = item.StartDate
                    g.afterDateValTo = item.EndDate
                }
            });

            return r
        },
        'level_types': function() {
            var r = [];
            var levels = Level.all();
            for (var i = 0; i < levels.length; i++) {
                if (r.indexOf(levels[i].LevelType) == -1) {
                    r.push(levels[i].LevelType)
                }
            }
            return r
        },
        'loading': function() {
            return this.$store.state.entities.level.loading;
        },
        'loaded': function() {
            return this.$store.state.entities.level.loaded;
        },
        
        // 'showOverride': function() {
        //     return this.value.OverrideLevelID > 0;
        // }
    },
    methods: {
        getLevels() {
            let g = this;
            QuilityAPI.getAgentManagement_Levels().then(function() {
                g.reset();
            })
        },
        saveLevel() {
            var g = this
            if (!this.$refs.form.validate()) {
                this.warning = "Please correct invalid fields."
                setTimeout(function() { g.warning = null }, 6000)
                this.saving = false;
                return false;
            }
            this.saving = true;
            if (this.AgentLevel.id == null) {
                var p = QuilityAPI.createAgentManagement_Level(this.AgentLevel)
            } else {
                p = QuilityAPI.updateAgentManagement_Level(this.AgentLevel)
            }
            //now process the promise. 
            p.then(function(resp) {
                    if (!resp.success) {
                        g.error = resp.message ? resp.message : resp.msg;
                        setTimeout(function() { g.error = null }, 6000)
                    } else {
                        g.$emit('saved', resp.data[0])
                    }
                    g.saving = false;
                })
                .catch(function(err) {
                    g.error = err.message
                    setTimeout(function() { g.error = null }, 6000)
                    g.saving = false;
                });
        },
        reset() {
            this.warning = null
            this.saving = false
            this.$set(this, "AgentLevel", this.value);
            this.showOverride = this.value.OverrideLevelID > 0 ? true : false
            //var level_index = this.indexByKey(this.AgentLevel.LevelID, this.levels)
            //this.LevelType = this.levels[level_index] ? this.levels[level_index].LevelType : null;
        },
        getAgentLevels() {
            const g = this
            g.$store.state.entities.level.loading = true
            QuilityAPI.getAgentManagement_AgentLevels(g.AgentLevel.AgentCode).then(function(data) {
                g.$set(g, 'history', data)
                g.historyLevels = g.history.filter(item => { return item.LevelType == g.LevelType });
                g.$store.state.history_levels = g.historyLevels
                g.$store.state.entities.level.loading = false
            }).catch(function(err) {
                if (err && err.msg) {
                    g.showError(err.msg)
                }
                g.$store.state.entities.level.loading = false
            })
        },
    },
    watch: {
        'value': function(newV) {
            //console.log("got new Level", newV)
        },
        'showOverride'(v) {
            if (!v) {
                this.AgentLevel.OverrideLevelID = null
            }
            //console.log("override", v)
        },
        'AgentLevel.LevelType': function(newV) {
            var g = this
            this.$nextTick(function(newV) {
                if (g.indexByKey(g.AgentLevel.LevelID, g.levels) == -1) {
                    g.AgentLevel.LevelID = null
                }
            })
        },
        'AgentLevel': function() {
            var g = this
            this.$nextTick(function(newV) {
                g.reset();
            })
        },
    },
    components: {
        QDatePicker
    }
}

</script>
