<template>
    <v-container fluid class="pa-0 pa-md-3">
        <v-row :no-gutters="$vuetify.breakpoint.mdAndDown">
            <v-col cols="12" md="6">
                <v-card color="q_leads_5" class="flex-grow-1 elevation-0">
                    <v-card-title id="status-reason" style="background-color:#360568; color:white;">
                        <v-icon color="q_leads_3" class="pr-3">fas fa-chart-pie</v-icon> <strong>Status Reason</strong>
                    </v-card-title>
                    <v-card-text class="pt-7">
                        <v-simple-table dense style="background:transparent; ">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Status Reason
                                        </th>
                                        <th class="text-left">
                                            Count
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(reasondata,index) in unique_status_count" :key="index">
                                        <td>{{ reasondata.StatusReason }}</td>
                                        <td>{{ reasondata.count }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card color="q_leads_5" class="flex-grow-1  elevation-0">
                    <v-card-title id="policy-status" style=" background-color:#360568; color:white;">
                        <v-icon color="q_leads_3" class="pr-3">fas fa-chart-pie</v-icon> <strong>Policy Status</strong>
                    </v-card-title>
                    <v-card-text class="pt-7">
                        <v-simple-table dense style="background:transparent; ">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Policy Status
                                        </th>
                                        <th class="text-left">
                                            Count
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(policydata,index) in unique_policy_count" :key="index">
                                        <td>{{ policydata.PolicyStatus }}</td>
                                        <td>{{ policydata.count }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row :no-gutters="$vuetify.breakpoint.mdAndDown">
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                        <QDataTableHeader color="q_leads_1" ratio="5/7">
                            <template #left>
                                <div id="q-pending-policy-step-3">
                                    <p class="q-display-3  pb-0 pt-0 mt-0 mb-0 white--text">{{ title }}</p>
                                    <p class="pt-0 mt-0 ">
                                        <v-btn text small color="q_leads_4" @click="toggleFilters">
                                            <v-icon small left>fas fa-filter</v-icon> Filter Pending Policies
                                        </v-btn>
                                    </p>
                                </div>
                            </template>
                            <template #right>
                                <v-spacer></v-spacer>
                                <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                            </template>
                        </QDataTableHeader>
                    </v-container>
                    <v-row class="d-flex justify-start" v-if="toggleFilters">
                        <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
                    </v-row>
                    <v-row class="ml-5 mb-3" v-if="response_data.length > 0">
                        <v-btn text small color="q_leads_4" @click="exportResults">
                            <v-icon small left>fas fa-file-export</v-icon> Export Results
                        </v-btn>
                    </v-row>
                    <QExpandableDataTable fluid :items-per-page.sync="rows" :search="search" :headers="allHeaders" :visibleHeaders="visibleHeaders" :items="all_data" item-key="ID" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="is_loading" :options.sync="options" :server-items-length="total_server_items" color="q_leads_1">
                        <template v-slot:item.ID="{ item }">
                            <v-btn icon color="q_leads_1" @click="viewPolicy(item)">
                                <v-icon>fas fa-eye</v-icon>
                            </v-btn>
                        </template>
                        <template #item.InsuredEmail="{ item }">
                            <span style="word-break: break-all;">{{ item.InsuredEmail }}</span>
                        </template>
                        <template #item.ReceivedDate="{ item }">
                            {{ item.ReceivedDate=="NA" ? "NA" : formatDate(item.ReceivedDate) }}
                        </template>
                        <template #item.AnnualizedPremium="{ item }">
                            <span v-if="item.AnnualizedPremium"><span>{{ formatCurrency(item.AnnualizedPremium) }}</span></span><span v-else>NA</span>
                        </template>
                        <template #item.FaceAmount="{ item }">
                            <span v-if="item.FaceAmount"><span>{{ formatCurrency(item.FaceAmount) }}</span></span><span v-else>NA</span>
                        </template>
                        <template #item.WritingNumber="{ item }">
                            <span v-if="item.WritingNumber"><span>{{ item.WritingNumber }}</span></span><span v-else>NA</span>
                        </template>
                        <template v-slot:item.AgentName="{ item }">
                            <span v-if="item.DoingBusinessAsName != 'N/A'">DBA : {{ item.DoingBusinessAsName }}</span>
                            <span v-else>{{ item.AgentName }}</span>
                        </template>
                        <template v-slot:item.CarrierLoginLink="{ item }">
                            <v-btn depressed dark color="q_leads_1" @click="loadCarrierLogin(item.CarrierLoginLink)">Carrier Login</v-btn>
                        </template>
                        <template v-slot:header.data-table-select="{item}">
                            <div>
                                <v-tooltip :open-on-hover="true" top>
                                    <template v-slot:activator="{ on }">
                                        <v-checkbox v-model="selectAll" v-on="on"></v-checkbox>
                                    </template>
                                    <span>Add All to Power Dailer</span>
                                </v-tooltip>
                            </div>
                        </template>
                    </QExpandableDataTable>
                    <div id="no-data-supplied" class="px-5" v-if="!for_baseshop">
                        <h3>Your Carriers</h3>
                        <p class="body-2">If you have a writing number with a carrier that is not listed below, please check your writing numbers in the licensing tab to ensure they are correct.</p>
                    </div>
                    <v-simple-table v-if="!for_baseshop" class="pa-5" style="background-color:#fff; max-width: 600px;">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Carrier Name</th>
                                <th>Agent Login</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="data in carrier_info" :key="data.ID">
                                <td>
                                    <v-icon color="error">fas fa-circle</v-icon>
                                </td>
                                <td>
                                    {{ data.CarrierName }}
                                </td>
                                <td>
                                    <v-btn depressed dark color="q_new_b_1" :href="data.AgentLoginURL" target="_blank">
                                        <template v-if="$vuetify.breakpoint.mdAndDown">
                                            <v-icon>fas fa-sign-in-alt</v-icon>
                                        </template>
                                        <template v-else>
                                            Carrier Login
                                        </template>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" width="400">
            <v-card class="pa-6 text-center" min-height="">
                <div class="pa-4 display-3 font-weight-bold">Dialog</div>
                <p>message goes here</p>
                <v-btn color="primary">Link To A Thing!</v-btn>
            </v-card>
        </v-dialog>
        <v-dialog v-model="pending_policy_detail" width="500">
            <v-card v-if="current_policy" class="pa-6 text-center" min-height="">
                <div>
                    <q-pending-policy-detail :policy="current_policy"></q-pending-policy-detail>
                </div>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin.js"
import Lead from '@/store/Models/Lead'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import QPendingPolicyDetail from '@/components/datatables/QPendingPolicyDetail.vue'
import QDataTableHeader from '../datatables/base/QDataTableHeader.vue'
import QExpandableDataTable from '../datatables/QExpandableDataTable.vue'

export default {
    //override this.
    name: "QPendingPolicyDataTable",
    mixins: [QDataTableMixin],
    props: ['agent', 'for_baseshop'],
    data: function() {
        return {
            data_loading: false,
            count_loading: false,
            entity: 'pendingpolicy',
            pending_policy_detail: false,
            is_exporting: false,
            //use this to "preset" a filter
            //filters: { 'Status': 'Active' },
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
            response_data: [],
            total_server_items: 0,
            current_policy: null,
            server_available_filters: null,
            unique_status_count: null, // holds the counts for each distinct status reason associated with the pending policies
            carrier_info: [], // holds the info about each of the carriers, if the agent has a writing number with them then ShowLogin will be true and the login button will be visable
            options: { sortBy: ['ReceivedDate'], sortDesc: ["true"] },
            unique_policy_count: null // holds the count for each policy status associated with the penind polices
        }
    },
    mounted: function() {
        this.refreshData();
        this.loadCounts()
    },
    computed: {
        filter_keys: function() {
            // these should match up with the keys for the model on the server side - availableFilters
            return ["PolicyStatus", "Carrier"];
        },
        all_data: function() {
            //instead of using a state/model on the frontend the data is all contained in this module.
            //this computed propery is required by the QDataTableMixin
            return this.response_data
        },

        allHeaders() {
            return [{
                    text: 'Agent Name',
                    value: 'AgentName',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                },
                {
                    text: 'Insured',
                    value: 'InsuredName',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    divider: true,
                    class: 'header-class',
                },
                {
                    text: 'Carrier',
                    value: 'CarrierName',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    divider: true,
                    class: 'header-class',
                },
                {
                    text: 'Application Date (Y/M/D)',
                    value: 'ReceivedDate',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                },
                {
                    text: 'Product',
                    value: 'ProductName',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                }, {
                    text: 'Status',
                    value: 'PolicyStatus',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    divider: true
                },
                {
                    text: 'Status Reason',
                    value: 'StatusReason',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                },
                {
                    text: 'Insured Phone',
                    value: 'InsuredPhone'
                },
                {
                    text: 'Insured Email',
                    value: 'InsuredEmail'
                },
                {
                    text: 'Policy Number',
                    value: 'PolicyNumber'
                },
                {
                    text: 'Annualized Premium',
                    value: 'AnnualizedPremium'
                },
                {
                    text: 'Status',
                    value: 'OrigStatus'
                },
                {
                    text: 'Face Amount',
                    value: 'FaceAmount'
                },
                {
                    text: 'Writing Number',
                    value: 'WritingNumber'
                },
                {
                    text: 'Carrier Login',
                    value: 'CarrierLoginLink',
                    align: 'center',
                    sortable: false,
                    filterable: false,
                    bottom_filter: false,
                    divider: true
                },
            ]
        },

        visibleHeaders() {
            const headers = [{
                    text: 'Agent Name',
                    value: 'AgentName',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                },
                {
                    text: 'Insured',
                    value: 'InsuredName',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    divider: true,
                    class: 'header-class',
                },
                {
                    text: 'Carrier',
                    value: 'CarrierName',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    divider: true,
                    class: 'header-class',
                },
                {
                    text: 'Application Date (Y/M/D)',
                    value: 'ReceivedDate',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                },
                {
                    text: 'Product',
                    value: 'ProductName',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                }, {
                    text: 'Status',
                    value: 'PolicyStatus',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    divider: true
                },
                {
                    text: 'Status Reason',
                    value: 'StatusReason',
                    align: 'start',
                    sortable: true,
                    divider: true,
                    filterable: true
                },
                {
                    text: 'Carrier Login',
                    value: 'CarrierLoginLink',
                    align: 'center',
                    sortable: false,
                    filterable: false,
                    bottom_filter: false,
                    divider: true
                }
            ]

            if (this.$vuetify.breakpoint.smAndDown) {
                return headers.slice(1, 3)
            }

            if (this.$vuetify.breakpoint.mdAndDown) {
                return headers.slice(0, 5)
            }


            return headers
        },

        //server_available_filters will be set by our first data pull where the server responds with all data for the filter drop downs.
        //QDataTableMixin requires this computed property.
        'available_filters': function() {
            return this.server_available_filters
        },
        is_loading: function() {
            return this.count_loading || this.data_loading
        }
    },
    methods: {
        //get called by the QDataTableMixin whenever we need to refresh the data from the server.
        loadData: debounce(function(opt) {
            var g = this
            this.data_loading = true;

            // if the prop for_baseshop
            // is set, then that means
            // that we are using this
            // compnent to expose the
            // policy data for the agents
            // in the baseshop of this
            // agent. This currently
            // happens when we access
            // this component from the
            // baseshop_pending_policy
            // view

            if (this.is_exporting) {
                opt.itemsPerPage = "csv"
            }

            if (this.for_baseshop) {
                // get baseshop polices
                // set component fields to work with agent policies
                QuilityAPI.getBaseShopPolicies(this.agent.AgentCode, this.filters, opt)
                    .then(function(json) {
                        if (g.is_exporting) {
                            var fileURL = window.URL.createObjectURL(new Blob([json]));
                            var fileLink = document.createElement('a');
                            fileLink.href = fileURL;
                            fileLink.setAttribute('download', 'baseshop-pending-policy-data.csv');
                            document.body.appendChild(fileLink);
                            fileLink.click();
                        } else {
                            //all the good data returned
                            g.response_data = json.data
                            //lets the data table know how many results the full set is from the server.
                            g.total_server_items = json.meta.total
                            if (typeof json.meta != 'undefined' && typeof json.meta.filters != 'undefined') {
                                g.server_available_filters = json.meta.filters
                            }
                        }
                        g.is_exporting = false;
                    })
            } else {
                // set component fields to work with agent policies
                QuilityAPI.getAgentPendingPolicies(this.agent.AgentCode, this.filters, opt).then(function(json) {
                    if (g.is_exporting) {
                        var fileURL = window.URL.createObjectURL(new Blob([json]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'pending-policy-data.csv');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    } else {
                        //all the good data returned
                        g.response_data = json.data

                        //lets the data table know how many results the full set is from the server.
                        g.total_server_items = json.meta.total
                        if (typeof json.meta != 'undefined' && typeof json.meta.filters != 'undefined') {
                            g.server_available_filters = json.meta.filters
                        }
                    }
                    g.is_exporting = false;
                })
            }

            this.data_loading = false
            this.firstPull = false
        }, 200),
        //show the policy detail popup dialog
        viewPolicy: function(item) {
            this.pending_policy_detail = true;
            this.current_policy = item
        },
        //when user clicks button in the datatable.
        loadCarrierLogin: function(url) {
            window.open(url, '_blank')
        },
        exportResults: function() {
            this.is_exporting = true
            this.refreshData();
        },
        loadCounts: async function(opt) {
            let g = this
            this.count_loading = true
            let agent_carriers = []

            if (this.for_baseshop) {
                // get baseshop policy status stats
                let json = await QuilityAPI.getAgentUniquePolicyStatusBaseShopCount(this.agent.AgentCode, this.filters, {})
                g.unique_policy_count = json.data

                // get baseshop stats
                json = await QuilityAPI.getBaseShopUniqueStatusCount(this.agent.AgentCode, this.filters, {})
                g.unique_status_count = json.data
            } else {
                // get baseshop policy status stats
                let json = await QuilityAPI.getAgentUniquePolicyStatusCount(this.agent.AgentCode, this.filters, {})
                g.unique_policy_count = json.data

                // get unique status count
                json = await QuilityAPI.getAgentUniqueStatusCount(this.agent.AgentCode, this.filters, {})
                g.unique_status_count = json.data

                // get this agents writing numbers
                json = await QuilityAPI.getAgentNonPolicySupplyingCarriers(this.agent.AgentCode)

                for (const policyData of json.data) {
                    // account for agents who
                    // may have multiple
                    // writing numbers with
                    // the same carrier

                    const carrierID = parseInt(policyData.CarrierID)

                    if (!agent_carriers.includes(carrierID)) {
                        agent_carriers.push(carrierID)
                    }
                }

                // get info on all of the carriers
                json = await QuilityAPI.getCarriers({ itemsPerPage: "all" })
                for (const carrierInfo of json.data) {
                    // populate carrier_info
                    // with all of the carrier
                    // data that we have. If
                    // the agent has a writing
                    // number for a given carrier
                    // than the login button will
                    // be available for that carrier

                    if (agent_carriers.includes(carrierInfo.ID)) {
                        g.carrier_info.push({
                            ...carrierInfo,
                        })
                    }
                }
            }
            this.count_loading = false
        }
    },
    watch: {
        'agent.AgentCode': function(newV) {
            this.$nextTick(this.refreshData) // this is the QDataTableMixin call that does stuff needed before actually calling loadData above.
        }
    },
    components: {
        QPendingPolicyDetail,
        QDataTableHeader,
        QExpandableDataTable,
    }
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}
</style>