<template>
    <v-container fluid class="pa-0 pa-md-3">
        <v-row :class="!$vuetify.breakpoint.sm && 'flex-wrap-reverse'">
            <v-col cols="12">
                <v-card class="elevation-0">
                    <v-container fluid class="ma-0 pa-0 q_leads_1" id="lead_datatable">
                        <QDataTableHeader color="q_leads_1" ratio="5/7">
                            <template #left>
                                <div id="q-pending-policy-step-3">
                                    <p class="q-display-3 d-flex align-center white--text">{{ title }}

                                        <v-switch
                                            class="ml-4"
                                            v-model="personal_only"
                                            inset
                                            dark
                                            :label="personal_only ? 'Personal Production' : 'Base Shop'"
                                            @change="refreshData()"
                                        ></v-switch>
                                    </p>
                                    <p class="pt-0 mt-0 ">
                                        <v-btn text small color="q_leads_4" @click="toggleFilters">
                                            <v-icon small left>fas fa-filter</v-icon> Filter Placed Policies
                                        </v-btn>
                                    </p>
                                </div>
                            </template>
                            <template #right>
                                <v-spacer></v-spacer>
                                <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                            </template>
                        </QDataTableHeader>
                    </v-container>
                    <v-row class="d-flex justify-start" v-if="toggleFilters">
                        <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
                    </v-row>
                    <v-row class="ml-5 mb-3" v-if="response_data.length > 0">
                        <v-btn text small color="q_leads_4" @click="downloadCsvData">
                            <v-icon small left>fas fa-file-export</v-icon> Export Results
                        </v-btn>
                    </v-row>
                    <v-data-table 
                        v-on:current-items="updateCurrentItems" 
                        :mobile-breakpoint="2" 
                        :items-per-page.sync="rows" 
                        v-model="selected" 
                        :headers="allHeaders" 
                        :items="all_data" 
                        item-key="id" 
                        class="elevation-0" 
                        :footer-props="{itemsPerPageOptions:[10,25,50,100]}" 
                        :loading="data_loading" 
                        :options.sync="options" 
                        :server-items-length="total_server_items"
                        >
                        <template v-slot:item.BusinessPeriodMonth="{item}">
                            <span class="nowrap">
                                {{item.BusinessPeriodMonth}}/{{item.BusinessPeriodYear}}
                            </span>
                        </template>
                        <template v-slot:item.AnnualizedPremium="{item}">
                            <span class="pr-8">{{formatCurrency(item.AnnualizedPremium)}}</span>
                        </template>
                        <template v-slot:item.Product="{item}">
                            <span class="pr-8">{{displayProductName(item)}}</span>
                        </template>
                    </v-data-table>
                    
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin.js"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import QDataTableHeader from '../datatables/base/QDataTableHeader.vue'
import QScoreCardFilters from '@/components/stats/scorecards/QScoreCardFilters.vue';

export default {
    name: "QPlacedPolicyDataTable",
    mixins: [QDataTableMixin],
    props: ['agent', 'for_baseshop'],
    data: function() {
        return {
            data_loading: false,
            count_loading: false,
            pending_policy_detail: false,
            is_exporting: false,
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
            response_data: [],
            total_server_items: 0,
            current_policy: null,
            server_available_filters: null,
            unique_status_count: null, // holds the counts for each distinct status reason associated with the pending policies
            carrier_info: [], // holds the info about each of the carriers, if the agent has a writing number with them then ShowLogin will be true and the login button will be visable
            options: { sortBy: ['CarrierDate'], sortDesc: ["true"] },
            unique_policy_count: null, // holds the count for each policy status associated with the penind polices
            the_data: [],
            filters: {
                BusinessPeriodMonth: "-All-",
                BusinessPeriodYear: "-All-",
                Carrier: "-All-",
                Product: "-All-"
            },
            personal_only: false
        }
    },
    mounted: function() {
        this.refreshData();
    },
    computed: {
        filter_keys: function() {
            // these should match up with the keys for the model on the server side - availableFilters
            return ["Carrier", "Product", "BusinessPeriodMonth", "BusinessPeriodYear"];
        },
        all_data: function() {
            //instead of using a state/model on the frontend the data is all contained in this module.
            //this computed propery is required by the QDataTableMixin
            return this.response_data
        },

        allHeaders() {
            return [{
                    text: 'Business Date',
                    value: 'BusinessPeriodMonth',
                    align: 'start',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Client',
                    value: 'Client',
                    align: 'start',
                    sortable: true,
                    filterable: false
                },
                {
                    text: 'Annualized Premium',
                    value: 'AnnualizedPremium',
                    align: 'right',
                    sortable: true,
                    filterable: false,
                    class: 'header-class',
                },
                {
                    text: 'Carrier',
                    value: 'Carrier',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    class: 'header-class',
                },
                {
                    text: 'Product',
                    value: 'Product',
                    align: 'start',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Transaction Type',
                    value: 'TransactionType',
                    align: 'start',
                    sortable: true,
                    filterable: true
                },
            ]
        },

        //server_available_filters will be set by our first data pull where the server responds with all data for the filter drop downs.
        //QDataTableMixin requires this computed property.
        'available_filters': function() {
            return this.server_available_filters
        },
        is_loading: function() {
            return this.count_loading || this.data_loading
        }
    },
    methods: {
        //get called by the QDataTableMixin whenever we need to refresh the data from the server.
        loadData: debounce(function(opt) {
            
            var g = this
            this.data_loading = true;

            if (this.is_exporting) {
                opt.itemsPerPage = "csv"
            }

            // set component fields to work with agent policies
            QuilityAPI.getAgentPlacedPolicies(this.agent.AgentCode, {...this.filters, personal_only: this.personal_only}, opt).then(function(json) {
                if (g.is_exporting) {
                    var fileURL = window.URL.createObjectURL(new Blob([json]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'pending-policy-data.csv');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                } else {
                    //all the good data returned
                    g.response_data = json.data
                    g.data_loading = false

                    //lets the data table know how many results the full set is from the server.
                    g.total_server_items = json.meta.total
                    if (typeof json.meta != 'undefined' && typeof json.meta.filters != 'undefined') {
                        g.server_available_filters = json.meta.filters
                    }
                }
                g.is_exporting = false;
            })
            
            this.firstPull = false
        }, 200),
        displayProductName(item){
            return item.ProductName ?? item.Product
        },
        exportResults: function() {
            this.is_exporting = true
            this.refreshData();
        },
        downloadCsvData: debounce(function() {
            var opt = {
                ...this.options,
                search: this.search,
            }
            var endpoint = `/api/private/policyplaced/agent/${this.agent.AgentCode}`;
            var params = {
                ...opt,
                ...this.filters,
                'itemsPerPage': "csv",
                'attr': ''
            }
            this.dispatchJob({
                'method': "GET",
                'path': endpoint,
                'params': params,
                'JobName': "Placed Policies Export"
            })
        }, 200),
        changeAgent: function() {

        }
    },
    watch: {
        'agent.AgentCode': function(newV) {
            this.$nextTick(this.refreshData) // this is the QDataTableMixin call that does stuff needed before actually calling loadData above.
        },
        table_filters: {
            handler(newV, oldV) {
                this.filters = {...this.table_filters, ...this.agent_filters}
            },
            deep: true
        },
        agent_filters: {
            handler(newV, oldV) {
                this.filters = {...this.table_filters, ...this.agent_filters}
            },
            deep: true
        }
    },
    components: {
        QDataTableHeader,
        QScoreCardFilters
    }
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}
</style>