<template>
    <q-submenu :base-url="baseUrl" :title="title" :titlelink="titlelink" :buttons="buttons" :submenu="submenu" ref="support" class="q-sticky"></q-submenu>
</template>
<script>
//table data + search
import QSubmenu from '@/components/navigation/Submenus/QSubmenu.vue'
import SupportTickets from '@/store/Models/SupportTicket'
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    props: ["hasDemo"],
    data() {
        return {
            title: "Support",
            baseUrl: '/support',
            titlelink: [{
                label: "",
                link: "",
            }]
        }
    },
    mounted: function() {

    },
    computed: {
        submenu: function() {
            var g = this;
            var submenu = [{
                label: "Help",
                to: "/support"
            }, ];
            if (this.hasDemo) {
                submenu.push({
                    label: "Start Demo",
                    fun: function() {
                        g.$emit("demo");
                    }
                })
            }
            return submenu;
        },

        'loading': function() {
            return this.tickets.length == 0 || this.$store.state.entities.support_ticket.loading;
        },
        'buttons': function() {
            if (this.hasTickets) {
                return [{
                        label: "Need Help?",
                        to: "/support",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-support-1",
                    },
                    {
                        label: "My Tickets",
                        to: "/support/tickets",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-support-5"
                    }
                ]
            }
            return [{
                label: "Need Help?",
                to: "/support",
                icon: "fas fa-arrow-alt-circle-right",
                id: "v-support-1",
            }];
        },
        tickets: function() {
            return SupportTickets.all();
        },
        hasTickets: function() {
            return this.tickets.length > 0;
        }
    },
    components: {
        QSubmenu
    },
    methods: {},
}

</script>
