<template>
    <div style="display:inline-block;">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon small fab color="primary" dark v-bind="attrs" v-on="on">
                    <v-icon left>fas fa-ellipsis-v</v-icon>
                </v-btn>
            </template>
            <v-list dense>
                <v-list-item class="clickable" v-for="(item, index) in permitted_admin_items" :key="index" @click="item.click" v-on:hover="">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <q-edit-agent-details v-model="showEditDetails" v-on:input="emitRefresh"></q-edit-agent-details>
        <q-edit-level v-model="editAgentLevel" v-on:input="emitRefresh" :agent="agent"></q-edit-level>
        <q-default-login-info v-model="showDefaultLogin" :agent="agent"></q-default-login-info>
        <q-edit-permissions v-model="showEditPermissions" :agent="agent"></q-edit-permissions>
        <q-edit-agent-config v-model="showEditAgentConfig" :agent="agent"></q-edit-agent-config>
        <q-sms-correspondence-dialog v-model="showSmsCorrespondence" :agent="agent"></q-sms-correspondence-dialog>
    </div>
</template>
<script>
import QEditAgentDetails from '@/components/agent_admin/QEditAgentDetails.vue';
import QEditPermissions from '@/components/agent_admin/QEditPermissions.vue';
import QEditLevel from '@/components/agent_admin/QEditLevel.vue';
import QDefaultLoginInfo from '@/components/agent_admin/QDefaultLoginInfo.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QEditAgentConfig from '@/components/agent_admin/QEditAgentConfig.vue'
import QSmsCorrespondenceDialog from '@/components/agent_admin/QSmsCorrespondenceDialog.vue'
export default {
    name: 'QAgentManagementMenu',
    props: ['agent'],
    data() {
        return {
            editAgentLevel: false,
            showEditDetails: false,
            showDefaultLogin: false,
            showEditPermissions: false,
            showEditAgentConfig: false,
            showSmsCorrespondence: false,
            //all the available items in the list... will be managed by permitted_admin_items in order to match up permissions with what is shown. 
            admin_items: [{
                    permission: "manage:Agent",
                    title: "Edit Agent Details",
                    click: this.displayEditDetails
                },
                {
                    permission: "manage:AgentLevel",
                    title: "Edit Level",
                    click: this.displayEditLevel
                },
                /*{
                    permission: "manage:AgentUpline",
                    title: "Change Upline",
                    click: this.displayEditUpline
                },*/
                {
                    permission: "manage:Agent",
                    title: "Default Login Info",
                    click: this.displayDefaultLoginInfo
                },
                {
                    permission: "impersonate:Agent",
                    title: "Impersonate",
                    click: this.impersonateAgent
                },
                {
                    permission: "manage:AgentLevel",
                    title: "Agent Audit Log",
                    click: this.gotoAuditLog
                },
                {
                    permission: "manage:AgentLevel",
                    title: "Writing Number Audit Log",
                    click: this.gotoWritingNumberAuditLog
                },
                {
                    permission: "manage:Agent",
                    title: "Agent Activity Log",
                    click: this.gotoAgentActivityLog
                },
                {
                    permission: "manage:AgentPermissions",
                    title: "Permissions",
                    click: this.displayEditAgentPermissions
                },
                {
                    permission: "manage:AgentConfig",
                    title: "Agent Configurations",
                    click: this.displayEditAgentConfig
                },
                {
                    permission: "manage:Agent",
                    title: "SMS Correspondence",
                    click: this.displaySmsCorrespondence
                }

            ]
        }
    },
    methods: {
        displayEditDetails() {
            this.showEditDetails = true;
        },
        displayEditLevel() {
            this.editAgentLevel = true;
        },
        displayEditAgentPermissions() {
            this.showEditPermissions = true;
        },
        displayEditAgentConfig() {
            this.showEditAgentConfig = true;
        },
        displayEditUpline() {
            this.editUpline = true;
        },
        displayDefaultLoginInfo() {
            this.showDefaultLogin = true;
        },
        gotoAuditLog() {
            this.$router.push({ name: 'AgentAuditReport', params: { agentcode: this.agent.AgentCode } })
        },
        gotoWritingNumberAuditLog() {
            this.$router.push({ name: 'WritingNumberAuditReport', params: { agentcode: this.agent.AgentCode } })
        },
        impersonateAgent() {
            QuilityAPI.impersonateAgent(this.agent.AgentCode).then(function(json) {
                if (json.success === true) {
                    document.location.href = process.env.VUE_APP_BASE_URL
                }
            })
        },
        emitRefresh() {
            this.$emit('refresh', true);
        },
        gotoAgentActivityLog() {
            this.$router.push({ name: 'UserLogReport', params: { agentcode: this.agent.AgentCode } })
        },
        displaySmsCorrespondence() {
            this.showSmsCorrespondence = true
        }
    },
    computed: {
        'permitted_admin_items': function() {
            var r = [];
            for (var i = 0; i < this.admin_items.length; i++) {
                if (this.hasPermission(this.admin_items[i].permission)) {
                    r.push(this.admin_items[i]);
                }
            }
            return r;
        }
    },
    watch: {

    },
    components: {
        QEditAgentDetails,
        QEditLevel,
        QDefaultLoginInfo,
        QEditPermissions,
        QEditAgentConfig,
        QSmsCorrespondenceDialog
    }
}

</script>
