<template>
    <div>
        <v-row>
            <v-col cols="12">
                <q-agent-lookup
                    :filters="{'Status' : 'Active,Available,Lapsed,Stalled,Terminated', 'DFLCertifiedInd' : 1}"
                    label="Search for a DFL Certified Agent"
                    v-model="searchAgentCode"
                    :rules="[rules.required]"
                    placeholder="Start typing to Search for an agent"
                    :add-self="true"
                >
                </q-agent-lookup>
            </v-col>
        </v-row>
        <v-row v-if="searchAgentCode">
            <v-col cols="12">
                <v-alert type="warning" v-if="invalidURL">
                    This does not appear to be a valid URL. This may cause problems when redirecting to this link.
                </v-alert>
                <v-text-field label="Add Calendar URL" v-model="calendarURL"></v-text-field>
            </v-col>
        </v-row>
        <v-row align="center" v-if="searchAgentCode && calendarURL">
            <v-col cols="12">
                <v-btn :disabled="loading" @click="addCalendarURLData">Add</v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import QAgentLookup from '@/components/utils/QAgentLookup.vue'
import rules from '@/components/utils/ValidationRules'
import QuilityAPI from "@/store/API/QuilityAPI.js"
export default {
    name: "QAgentCalendarLinkForm",
    props: ['calendarAgentCode'],
    data: function() { // data internal to component (not available outside)
        return {
            searchAgentCode: null,
            rules: rules,
            calendarURL: null,
            hasCalendarData: false,
            loading: false,
            data_loading: false,
        }
    },
    mounted: function() {
    },
    computed: {
        invalidURL: function()
        {
            return !this.validURL(this.calendarURL)
        },
    },
    methods: {
        validURL: function(url) {
            var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            return !!pattern.test(url);
        },
        addCalendarURLData()
        {
            const calendarData = {
                'AgentCode': this.searchAgentCode,
                'CalendarURL': this.calendarURL,
                'Approved': false,
                'ApprovedBy': null,
            }

            let g = this
            if(this.hasCalendarData){
                // update agent calendar pool
                // create agent calendar pool
                QuilityAPI.addAgentConfigAppointmentCalendar(this.calendarAgentCode, calendarData)
                .then(function (json) {
                    g.hasCalendarData = true
                    g.searchAgentCode = null
                    g.calendarURL = null
                    g.data_loading = false
                    g.$emit('calendarLinkAdded')
                })
                .catch(function(err) {
                    console.error('Issue with loading in calendar link data',err)
                    g.showError("Error adding calendar link.")
                    g.data_loading = false
                    g.$emit('calendarLinkAdded')
                })
            }
            else{
                // create agent calendar pool
                QuilityAPI.addAgentConfigAppointmentCalendar(this.calendarAgentCode, calendarData)
                .then(function (json) {
                    g.hasCalendarData = true
                    g.data_loading = false
                    g.$emit('calendarLinkAdded')
                })
                .catch(function(err) {
                    console.error('Issue with loading in calendar link data',err)
                    g.showError("Error adding calendar link.")
                    g.data_loading = false
                    g.$emit('calendarLinkAdded')
                })
            }
        },
        checkForCalendarPool(agentCode)
        {
            this.data_loading = true
            let g = this
            // pull data
            if(agentCode){
                QuilityAPI.getAgentConfigsAppointmentCalendar(agentCode)
                .then(function (json) {
                    g.hasCalendarData = (json.data) && (json.data.length > 0)
                    g.data_loading = false
                })
                .catch(function(err) {
                    console.error('Issue with loading in calendar link data',err)
                    g.showError("Error loading calendar links.")
                    g.data_loading = false
                })
            }
            else{
                this.data_loading = false
            }
        },
    },
    watch: {
        searchAgentCode: function(newV){

            if(newV && this.calendarAgentCode && !this.hasCalendarData){
                this.checkForCalendarPool(this.calendarAgentCode)
            }
        }
    },

    components: {
        QAgentLookup,
    }
}
</script>
<style scoped>
</style>