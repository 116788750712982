<template>
    <v-container fluid class="mt-0 px-0 pt-0 pb-12" style="background-color:#fff;">
        <q-product-library-agent-submenu></q-product-library-agent-submenu>
        <div class="q-sticky-buffer">
            <!-- CORE CARRIERS -->
            <v-container fluid>
                <v-row class="d-flex align-center px-5" no-gutters>
                    <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="12" md="5">
                        <h3 v-if="view_mode === 'cards'">Core Carriers</h3>
                        <h3 v-else>Core Carriers</h3>
                    </v-col>
                    <v-col cols="12" md="7" class="d-flex justify-end">
                        <div class="pr-2 flex-grow-1">
                            <v-select
                                label="Solutions"
                                :items="solution_filter_items"
                                multiple v-model="solution_filter"
                                :loading="solution_filter_items.length === 0" />
                        </div>
                        <v-switch
                            v-model="view_mode"
                            label="List View"
                            color="success" inset
                            hide-details false-value="cards"
                            true-value="table"
                            style="white-space: nowrap;" />
                    </v-col>
                </v-row>
            </v-container>

            <v-container fluid v-if="view_mode === 'cards'">
                <q-core-carrier-cards :carriers="coreCarriers" />
                <h3 class="px-5 transparent">Quility Carrier Network</h3>
                <q-carrier-cards :carriers="nonCoreCarriers" />
            </v-container>
            <v-container fluid v-else>
                <template>
                    <h3 class="px-5 mb-0  transparent" v-if="$vuetify.breakpoint.smAndDown">Core Carriers</h3>
                    <q-carrier-list
                        :carriers="coreCarriers"
                        :loading="loading"
                        :hide-default-header="$vuetify.breakpoint.smAndDown"
                        :hide-default-footer="$vuetify.breakpoint.smAndDown"
                        :items-per-page="-1"
                    />
                    <h3 class="px-5 mb-0 mt-4 transparent">Quility Carrier Network</h3>
                    <q-carrier-list
                        :carriers="nonCoreCarriers"
                        :loading="loading"
                        :hide-default-header="$vuetify.breakpoint.smAndDown"
                        :hide-default-footer="$vuetify.breakpoint.smAndDown"
                        :items-per-page="-1"
                    />
                </template>
                <!-- <template v-else>
                    <q-carrier-list :carriers="carriers" :loading="loading" hightlight-core-carrier />
                </template> -->
            </v-container>
        </div>
    </v-container>
</template>

<script>
import QProductLibraryAgentSubmenu from '@/components/navigation/Submenus/QProductLibraryAgentSubmenu.vue';
import QuilityAPI from '@/store/API/QuilityAPI.js';
import QCoreCarrierCards from '@/CMS/views/ContentBlocks/Elements/CoreCarrierCards.vue';
import QCarrierCards from '@/CMS/views/ContentBlocks/Elements/CarrierCards.vue';
import QCarrierList from '@/CMS/views/ContentBlocks/Elements/CarrierList.vue';

export default {
    name: 'AgentCarriers',

    components: {
        QProductLibraryAgentSubmenu,
        QCarrierCards,
        QCoreCarrierCards,
        QCarrierList,
    },

    data() {
        return {
            original_carriers: [],
            carriers: [],
            loading: false,
            view_mode: 'cards',
            solution_filter_items: [],
            solution_filter: [],
        };
    },

    mounted() {
        this.loadCarriers({ itemsPerPage: 'all' });
        if (window.localStorage.getItem('CarrierViewMode') === 'table') this.view_mode = 'table';
        else if (window.localStorage.getItem('CarrierViewMode') === 'cards') this.view_mode = 'cards';
    },

    computed: {
        coreCarriers() {
            return this.carriers.filter((carrier) => carrier.CoreCarrierInd === '1');
        },
        nonCoreCarriers() {
            return this.carriers.filter((carrier) => carrier.CoreCarrierInd !== '1');
        },
    },

    methods: {
        loadCarriers() {
            this.loading = true;

            QuilityAPI
                .getCarriers({ itemsPerPage: 'all', sortBy: 'CarrierName', sortDesc: false })
                .then((json) => {
                    this.loading = false;

                    const carriers = json.data.map((carrier) => {
                        const solutions = [];

                        if (carrier.products && carrier.products.length > 0) {
                            carrier.products.forEach((product) => {
                                if (product.ProductSolution === null) { return; }

                                const productSolution = product.ProductSolution.trim();

                                if (!solutions.includes(productSolution)) {
                                    solutions.push(productSolution);
                                }
                            });
                        }
                        return { ...carrier, solutions };
                    });

                    this.carriers = this.filterCarriersByAgentDivision(carriers);
                    this.original_carriers = this.carriers.slice();
                    this.setSolutionFilterItems();
                }).catch((err) => {
                    this.data_loading = false;
                    if (err.status === 431) {
                        this.showError(`${err} You probably need to clear all your cookie and session variables and login again.`);
                        return;
                    }
                    this.showError(err.message);
                });
        },
        setSolutionFilterItems() {
            const solutions = [];
            this.carriers.forEach((carrier) => {
                carrier.products.forEach((product) => {
                    solutions.push(product.ProductSolution);
                });
            });

            const options = [];
            solutions.forEach((val) => {
                if (val === null) { return; }
                const trimmedValue = val.trim();
                if (!options.includes(trimmedValue)) options.push(trimmedValue);
            });
            options.sort();
            this.solution_filter_items = options;
        },
        applySolutionFilters() {
            if (this.solution_filter.length === 0) {
                this.carriers = this.original_carriers;
            } else {
                this.carriers = this.original_carriers.filter((carrier) => {
                    const matches = carrier.solutions.filter((solution) => this.solution_filter.includes(solution));
                    return matches.length > 0;
                });
            }
        },
        filterCarriersByAgentDivision(carriers) {
            let userDivision;

            if (this.hasRole(['Staff', 'Exec', 'SuperAdmin'])) {
                // for staff only show the division they have selected to preview.
                userDivision = this.user.all_divisions.find((division) => String(division.Division) === String(this.divisions[0]));
            } else {
                userDivision = this.user.all_divisions.find((division) => String(division.Division) === String(this.user.Agent.Division));
            }
            // Find Division by the Division name assigned to this user

            // filter carriers by its division settings
            return carriers.filter((carrier) => {
                // a place to store division and its parent division settings (asusming only two levels of parent/child divisions)
                const divisionIndicators = {
                    divisionActive: '0',
                    divisionHidden: '0',
                    parentActive: '0',
                    parentHidden: '0',
                };
                carrier.divisions.forEach((division) => {
                    if (String(division.DivisionID) === String(userDivision.ID)) {
                        divisionIndicators.divisionActive = division.ActiveInd;
                        divisionIndicators.divisionHidden = division.HiddenInd;
                    } else if (String(division.DivisionID) === String(userDivision.ParentDivisionID)) {
                        divisionIndicators.parentActive = division.ActiveInd;
                        divisionIndicators.parentHidden = division.HiddenInd;
                    }
                });

                if (divisionIndicators.divisionActive === '1' && divisionIndicators.divisionHidden !== '1' && divisionIndicators.parentHidden !== '1') return true;
                if (divisionIndicators.parentActive === '1' && divisionIndicators.parentHidden !== '1') return true;
                return false;
            });
        },
    },

    watch: {
        view_mode(newV) {
            window.localStorage.setItem('CarrierViewMode', newV);
        },
        solution_filter() {
            this.applySolutionFilters();
        },
        divisions() {
            this.carriers = [];
            this.$nextTick(() => {
                this.loadCarriers();
            });
        },
    },
};
</script>
